html, body {
  background-color: #000;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding:0; 
  font-family:Arial, Helvetica, sans-serif; 
  font-size:.9em; 
  color:#FFF;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
};

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.cp1 {
  width: 40%;
  margin: 0 auto;
}

/* Main Styles */
/* body {background:#000; margin:0px; padding:0px; font-family:Arial, Helvetica, sans-serif; font-size:.9em; color:#FFF;} */
h1 {font-size:1.4em; color:#CCC;}

/* Diecast Styles */
#DiecastHomeContainer {width:990px; margin:0px auto;}
#DiecastDetailsContainer {width:825px; margin:0px auto;}
#carContainer {width:150px; height:175px; float:left; padding-right:15px;}

#detailsTable {vertical-align:top; border:1px solid #A9A9A9; margin-bottom:25px;}
	#detailsTable th {border-right:1px solid #A9A9A9; border-bottom:1px solid #A9A9A9; padding:2px 10px;}
	#detailsTable td {border-right:1px solid #A9A9A9; padding:2px 10px;}
	
	
	
.imageGallery {padding:0px 15px 10px 0px;}
.resizeme {margin-bottom:10px;}
.clear {clear:both;}
.clean {list-style-type:none; margin:0px; padding:0px;}
.logo {width:660px; margin:0px auto;}
.floatLeft {float:left;}
.center {text-align:center;}

/* Forza Track Styles */
#ForzaHomeContainer {width:800px; margin:0px auto;}
.trackContainer {width:128px; margin-right:15px; float:left;}
.trackBuffer {width:715px; margin:0px auto;}
.trackImage {float:left; min-width:128px; max-width:202px; margin-right:10px;}

#ForzaTracksContainer {width:715px; margin:0px auto;}
#ForzaDetailsContainer {min-width:600px; max-width:700px; margin:0px auto;}
.imageBump {margin-right:10px;}
.countryImgSpacer {margin-bottom:25px;}

/* Homing.htm & Pics pages Styles */
#IconContainer {width:660px; margin:0px auto; padding-top:45px;}
	#pics {float:left; width:180px; margin-right:20px;}
	#video {float:left; width:200px; margin-right:20px;}
	#misc {float:left; width:180px; margin-right:20px;}
	#email {clear:both; width:167px; margin:245px auto 0px auto;} /* on all pages */
	#warped {float:left; width:256px; margin-right:20px;}
	#caddy {float:left; width:121px; margin-right:20px;}
	#caddyImgSet {float:left; width:145px; margin-right:20px;}
	#caddyTreasureHunt {width:250px; margin:-115px 0px 0px -250px;}
	
#CaddyImgContainer {width:845px; margin:0px auto;}

/* Video pages Styles */
#VideoContainer {width:660px; margin:0px auto;}
	.movieSet {float:left; min-width:200px; max-width:300px; margin-right:20px;}
	
#VideoDetailsContainer {width:300px; margin:0px auto;}